import {
	FIREBASE_API_KEY,
	FIREBASE_APP_ID,
	FIREBASE_AUTH_DOMAIN,
	FIREBASE_DATABASE_URL,
	FIREBASE_MEASUREMENT_ID,
	FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET
} from 'constants/envVars';

export const firebaseConfig = {
	apiKey: FIREBASE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	databaseURL: FIREBASE_DATABASE_URL,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
	appId: FIREBASE_APP_ID,
	measurementId: FIREBASE_MEASUREMENT_ID
};

//Firebase Events - same as mobile
export const CARD_EXPAND = 'card_expand'; // Expanded the details of an Advisory card (more button)
export const CARD_COLLAPSE = 'card_collapse'; // Collapse the details of an Advisory card (less button)
export const DATA_LEARN_MORE = 'data_learn_more';
export const ERROR_AIRSPACE = 'error_airspace'; // When a pin is dropped, and we have an error getting airspace information
export const GET_LAANC = 'kh_link_tap'; // "Get LAANC" Button on LAANC info page clicked
export const MAP_SETTINGS_CLOSE = 'map_settings_close'; // User closed the map settings popup
export const MAP_SETTINGS_OPEN = 'map_settings_open'; // User opens the map settings popup
export const MENU_CONTACT = 'menu_contact'; // User taps on "Contact Us" in the menu
export const MENU_PILOT_INSTITUTE = 'menu_pilot_institute'; // User taps on "About Pilot Institute" in the menu
export const MENU_KH = 'menu_kh'; // User taps on "About Aloft" in the menu
export const MENU_TERMS = 'menu_terms'; // User taps on "Terms" in the menu
export const OPEN = 'open'; // Application is opened
export const PIN_DROP_GPS = 'pin_drop_gps'; // If a Pin is dropped at a location based on GPS
export const PIN_DROP_SEARCH = 'pin_drop_search'; // If a Pin is dropped at a location based on a search result or search lat/long
export const PIN_DROP_TAP = 'pin_drop_tap'; // Tap to Drop Pin
export const STYLE_AC_DEFAULT = 'style_ac_default'; // User selects Map Style "Air Control Light"
export const STYLE_AC_TERRAIN = 'style_ac_terrain'; // User selects Map Style "Air Control Terrain"
export const STYLE_SATELLITE = 'style_satellite'; // User selects Map Style "Satellite"

//Firebase Events - unique to web
export const SHARE_TWITTER = 'share_twitter';
export const SHARE_FACEBOOK = 'share_facebook';
export const SHARE_LINKEDIN = 'share_linkedin';
export const SHARE_EMAIL = 'share_email';
export const SHARE_LINK = 'share_link';
export const MAP_RESIZE_SMALLER = 'map_resize_smaller';
export const MAP_RESIZE_BIGGER = 'map_resize_bigger';
export const NOTIFY = 'notify';
export const NOTIFY_RECREATIONAL = 'notify_recreational';
export const NOTIFY_COMMERCIAL = 'notify_commercial';
export const NOTIFY_GOVERNMENT = 'notify_government';
export const NOTIFY_FIRST_RESPONDER = 'notify_first_responder';
export const GO_TO_AIR_CONTROL = 'go_to_air_control';
export const FREE_COURSES = 'free_courses';
