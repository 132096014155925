import { BASE_ALOFT_URL } from 'constants/envVars';
import axios from 'axios';
import { addAlert } from 'actions/alerts';
import { createErrorAlert } from 'utils/generalUtils';
import { ERROR_AIRSPACE } from 'constants/firebase';
import { logEvent } from 'actions/firebase';
import axiosInstance from 'services/axiosConfig';

export const getAdvisories =
	({ lat, long }) =>
	async (dispatch, getState) => {
		try {
			const url = `${BASE_ALOFT_URL}/v1/airspace/advisories?lat=${lat}&lng=${long}`;
			const response = await axiosInstance.get(url);
			return response.data.data;
		} catch (error) {
			logEvent(ERROR_AIRSPACE);
			const alert = createErrorAlert({ error });
			dispatch(addAlert(alert));
			console.error(error);
		}
	};

export const fetchMapLayerSourceData = url => async (dispatch, getState) => {
	try {
		const response = await axios.get(url);
		return response.data;
	} catch (error) {
		const alert = createErrorAlert({ error });
		dispatch(addAlert(alert));
		console.error(error);
	}
};

export const getTileServerRegionSpecificLayersAndSources = region => async dispatch => {
	try {
		const url = `${BASE_ALOFT_URL}/v1/airspace/layers?region=${region}&appends[]=map_styles&context=airaware&data_formats[]=geojson&data_formats[]=vector&data_formats[]=raster-dem`;
		const res = await axiosInstance.get(url);
		return res.data.data;
	} catch (error) {
		const alert = createErrorAlert({ error });
		dispatch(addAlert(alert));
		console.error(error);
	}
};
