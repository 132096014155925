import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as LAANCIcon } from 'assets/icons/laancIcon.svg';
import { ReactComponent as PilotInstituteIcon } from 'assets/icons/pilotInstitute.svg';

import { contactUsLink, laancLink, freeCoursesLink } from 'constants/externalLinks';

export const leftNavItems = [
	{
		icon: <LAANCIcon />,
		name: 'Get LAANC',
		link: laancLink
	},
	{ icon: <PilotInstituteIcon />, name: 'Free Courses', link: freeCoursesLink },
	{ icon: <ContactIcon />, name: 'Contact Us', link: contactUsLink }
];
