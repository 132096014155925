import axios from 'axios';
import { SANCTUM_TOKEN } from 'constants/envVars';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async request => {
	//Append token to request
	request.headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
		'X-Requested-With': 'XMLHttpRequest'
	};
	request.headers.Authorization = `Bearer ${SANCTUM_TOKEN}`;
	return request;
});

export default axiosInstance;
